import axios from "axios";

const server = cookie => {
	return axios.create({
		baseURL: process.env.REACT_APP_API_URL,
		headers: {
			Authorization: `Bearer ${cookie.get("token")}`,
			Accept: "application/json",
		},
	});
};

export const uploadServer = cookie => {
	return axios.create({
		baseURL: process.env.REACT_APP_API_URL,
		headers: {
			Authorization: `Bearer ${cookie.get("token")}`,
			Accept: "application/json",
			"Content-Type": "multipart/form-data",
		},
	});
};

export default server;

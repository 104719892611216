import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import { updateMeta } from "../../store/actions/metaAction";
import { Col, Row, Container } from "reactstrap";
import { v4 } from "uuid";

const CommentsCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [comment, setComment] = useState({});
	const [loading, setLoading] = useState(false);
	const [picture, setPicture] = useState({
		blob: true,
		path: userPlaceholder,
		placeholder: true,
	});
	const dispatch = useDispatch();

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة الانجازات جديد",
				breadcrumb: "الانجازات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	useEffect(() => {
		onStart();
	}, []);

	const handlePicture = e => {
		const fileInput = document.querySelector(`#${e.target.id}`);
		try {
			setPicture({
				path: URL.createObjectURL(fileInput.files[0]),
				blob: true,
				placeholder: false,
			});
		} catch (error) {}
	};
	const handleSubmit = e => {
		e.preventDefault();
		if (
			picture.placeholder ||
			!comment.name.ar ||
			!comment.opinion.ar ||
			!comment.name.en ||
			!comment.opinion.en
		) {
			toast.warn("الرجاء ادخال جميع البيانات المطلوبة");
			return;
		}
		const pictureElement = document.querySelector("#picture");
		const mainImage = new FormData();
		mainImage.append("file", pictureElement.files[0]);
		axios
			.post(`${process.env.REACT_APP_MAIN_URL}/api/upload`, mainImage, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				const data = {
					...comment,
					title: "comment",
					image: {
						path: response.data.data.file.path,
						blob: false,
						placeholder: false,
					},
				};
				axios
					.post(
						`${process.env.REACT_APP_API_URL}pages/comments/sections`,
						{
							section: data,
						},
						{
							headers: {
								Authorization: `Bearer ${cookie.get("token")}`,
								Accept: "application/json",
							},
						}
					)
					.then(
						_ =>
							toast.success("تم اضافة التعليق بنجاح") &&
							history.push("/comments/index")
					)
					.catch(_ => toast.error("حدث خطا !"));
			})
			.catch(_ => {
				toast.error("حدث خطا أثناء رفع الصورة");
				setTimeout(
					__ => toast.warn("الرجاء المحاولة مره اخرة او تغير الصورة"),
					800
				);
			});
	};

	return (
		<section>
			<Container>
				<div className="card">
					<div className="card-body">
						<Row style={{ marginTop: "2rem" }}>
							<Col
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<div className="form-item">
									<label htmlFor="clientNameAR">اسم المستخدم</label>

									<input
										type="text"
										id="clientNameAR"
										name="clientNameAr"
										onChange={e => {
											setComment({
												...comment,
												name: { ...comment.name, ar: e.target.value },
											});
										}}
									/>
								</div>
							</Col>

							<Col
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<div className="form-item">
									<label htmlFor="clientNameEN">اسم المستخدم بالانجليزية</label>

									<input
										type="text"
										id="clientNameEN"
										name="clientNameEn"
										onChange={e => {
											setComment({
												...comment,
												name: { ...comment.name, en: e.target.value },
											});
										}}
									/>
								</div>
							</Col>
						</Row>
						{/* <Row style={{ paddingTop: "1rem" }}>
							<Col
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<div className="form-item">
									<label htmlFor="JobNameAR">الوظيفة</label>

									<input
										type="text"
										id="JobNameAR"
										name="jobNameAR"
										onChange={e => {
											setComment({
												...comment,
												position: { ...comment.position, ar: e.target.value },
											});
										}}
									/>
								</div>
							</Col>

							<Col
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<div className="form-item">
									<label htmlFor="JobNameEN"> الوظيفة بالانجليزية </label>

									<input
										type="text"
										id="JobNameEN"
										name="jobNameEN"
										onChange={e => {
											setComment({
												...comment,
												position: { ...comment.position, en: e.target.value },
											});
										}}
									/>
								</div>
							</Col>
						</Row> */}
						<Row>
							<Col>
								<div className="form-item">
									<label htmlFor="CommentAR">تفصيل التعليق </label>

									<textarea
										name=""
										id="CommentAR"
										rows="5"
										style={{ resize: "none", width: "100%" }}
										onChange={e => {
											setComment({
												...comment,
												opinion: { ...comment.opinion, ar: e.target.value },
											});
										}}
									></textarea>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="form-item">
									<label htmlFor="CommentEN">تفصيل التعليق بالانجليزية</label>

									<textarea
										name=""
										id="CommentEN"
										rows="5"
										style={{ resize: "none", width: "100%" }}
										onChange={e => {
											setComment({
												...comment,
												opinion: { ...comment.opinion, en: e.target.value },
											});
										}}
									></textarea>
								</div>
							</Col>
						</Row>

						<Row>
							<Col>
								<div className="form-item">
									<label htmlFor="picture">صورة العميل</label>
									<input type="file" onChange={handlePicture} id="picture" />
									<img
										src={
											picture && picture.blob
												? picture.path
												: `${process.env.REACT_APP_API_URL}/${picture.path}`
										}
										alt="avatar"
										className="imagePreview-2"
									/>
								</div>
							</Col>
						</Row>

						<Row>
							<Col>
								<div className="form-item mt-3">
									<button
										className="bt bt-success btn-block"
										onClick={handleSubmit}
									>
										ارسال
									</button>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</Container>
		</section>
	);
};

export default CommentsCreate;

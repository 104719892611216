import * as types from "../types";

const initialState = {
	user: {},
	isLoggedIn: false,
};

export const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.LOG_IN:
			return {
				...state,
				user: action.payload,
				isLoggedIn: true,
			};
		case types.LOG_OUT:
			return { ...state, isLoggedIn: false, user: {} };
		default:
			return state;
	}
};

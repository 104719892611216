import React, { Fragment, useEffect, useReducer, useState } from "react";
import Loader from "../../components/shared/Loader";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateMeta } from "../../store/actions/metaAction";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import cookie from "js-cookie";
import server, { uploadServer } from "../../api/server";
import ReactPlayer from "react-player";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { Input, Select } from "../../components/shared/FormComponents";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import Select2 from "react-select";
import makeAnimated from "react-select/animated";

const RealEstateCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
		reset,
	} = useForm({
		defaultValues: {
			features: [],
		},
	});
	const {
		fields: featureFields,
		append: featureAppend,
		remove: featureRemove,
	} = useFieldArray({
		control,
		name: "features",
	});
	const {
		fields: descriptionFields,
		append: descriptionAppend,
		remove: descriptionRemove,
	} = useFieldArray({
		control,
		name: "descriptions",
	});

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		multiple: false,
		onDrop: acceptedFiles => {
			setFiles(
				acceptedFiles.map(file =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	const animatedComponents = makeAnimated();

	// initial states
	const [category, setCategory] = useState([]);
	const [types, setTypes] = useState([]);
	const [allApartMents, setAllApartMents] = useState([]);

	// form Needed states
	const [currentIftame, setCurrentIftame] = useState("");
	const [currentVideo, setCurrentVideo] = useState(userPlaceholder);
	const [imageArray, setImageArray] = useState([]);
	const [files, setFiles] = useState([]);
	const [, forceUpdate] = useReducer(x => x + 1, 0);

	const onStart = async () => {
		setLoading(true);
		dispatch(
			updateMeta({
				title: "اضافة عقار جديدة",
				breadcrumb: "العقارات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		await Promise.all([
			await server(cookie).get("/pages/real-estate"),
			await server(cookie).get("/pages/categories"),
			await server(cookie).get("/pages/apartment-type"),
		])
			.then(data => {
				setAllApartMents([
					...data[0].data.data.page.sections.map(data => ({
						...data,
						label: data.name.ar,
						value: data.slug,
					})),
				]);
				setCategory([
					...data[1].data.data.page.sections.map(data => ({
						name: data.name.ar,
						slug: data.slug,
					})),
				]);
				setTypes([
					...data[2].data.data.page.sections.map(data => ({
						name: data.name.ar,
						slug: data.slug,
					})),
				]);
			})
			.catch(error => toast.error(error));
		setLoading(false);
	};

	// render methods
	const thumbs = files.map(file => (
		<div className="thumb" key={file.name}>
			<div className="thumbInner">
				<img src={file.preview} className="img" />
			</div>
		</div>
	));

	// handleMethods

	const handleAddFeature = () => {
		if (featureFields.length + 1 > 6) {
			toast.info("اقص عداد للمميزات هو 6 ميزات");
			return;
		}
		featureAppend({ ar: null, en: null, count: null, icon: userPlaceholder });
	};
	const handleAddDescriptions = () => {
		if (descriptionFields.length + 1 > 12) {
			toast.info("اقص عداد للوصف هو 12");
			return;
		}
		descriptionAppend({ ar: null, en: null });
	};

	const deleteImage = (event, img) => {
		event.preventDefault();
		const arr = imageArray.filter(item => {
			return item !== img;
		});
		setImageArray(arr);
		setValue("images", arr);
	};
	const handleImageUpload = async e => {
		if (!files.length) {
			toast.error("برجاء إختيار صورة اولا");
			return;
		}
		e.preventDefault();
		setLoading(true);
		for (let i = 0; i < files.length; i++) {
			const formData = new FormData();
			formData.append(`file`, files[i]);
			await axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(function (responseImage) {
					imageArray.push(responseImage.data.data.file.path);
					setImageArray(imageArray);
					setValue("images", [imageArray[0]]);
				})
				.catch(function (error) {
					toast.error("خطأ، برجاء المحاولة مره اخرى!");
					setLoading(false);
				});
		}
		setLoading(false);
		toast.success("تم رفع الصور بنجاح!");
	};
	const mainHandleImageUpload = async (element, setter) => {
		setLoading(true);
		const formData = new FormData();
		formData.append(`file`, element.target.files[0]);
		await axios
			.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(function (responseImage) {
				setter(responseImage.data.data.file.path);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});

		setLoading(false);
		toast.success("تم رفع الصور بنجاح!");
	};

	const handleFeaturePicture = async index => {
		const tempValue = getValues(`features.${index}.icon`);
		if (tempValue.length < 1) return;
		setLoading(true);
		const blobUrl = URL?.createObjectURL(tempValue?.[0]);
		const formData = new FormData();
		formData.append(`file`, tempValue?.[0]);
		setLoading(false);
		await axios
			.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(function (responseImage) {
				setValue(
					`features.${index}.iconPath`,
					responseImage.data.data.file.path
				);
				setValue(`features.${index}.icon`, blobUrl);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
		forceUpdate();
		setLoading(false);
		toast.success("تم رفع الصور بنجاح!");
	};

	const submitHandler = async data => {
		setLoading(true);
		try {
			const finalData = {
				...data,
				features: [
					...data.features.map(({ iconPath, ...feature }) => ({
						...feature,
						icon: iconPath,
					})),
				],
				title: `${data?.name.en} real estate`,
				images: [...imageArray],
			};
			const dataResponse = await server(cookie).post(
				"/pages/real-estate/sections",
				{
					section: finalData,
				}
			);
			reset();
			onStart();
			setFiles([]);
			setCurrentIftame("");
			setCurrentVideo("");
			setImageArray([]);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	// watchers
	useEffect(() => {
		onStart();
	}, []);

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit(submitHandler)}>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="row">
								<div className="col-md-6">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="category">
												اختار الفئة التي تبحث عنها
											</label>
											<Select
												name="category"
												id="category"
												options={[...category]}
												register={register}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="type">اختار النوع</label>
											<Select
												name="type"
												id="type"
												options={[...types]}
												register={register}
											/>
										</div>
									</div>
								</div>

								<div className="col-md-6">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="price">السعر الحالي</label>
											<Input
												type="number"
												register={register}
												id="price"
												name="price.current"
												required={true}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="priceOld">السعر القديم</label>
											<Input
												register={register}
												type="number"
												id="priceOld"
												name="price.old"
												required={true}
											/>
										</div>
									</div>
								</div>

								<div className="col-md-6">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="nameAr">اسم العقار باللغة العربية</label>
											<Input
												type="text"
												name="name.ar"
												id="nameAr"
												register={register}
												required={true}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="nameEn">
												اسم العقار بالغة الانجليزية
											</label>
											<Input
												type="text"
												name="name.en"
												id="nameEn"
												register={register}
												required={true}
											/>
										</div>
									</div>
								</div>

								<div className="col-md-3">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="rooms">عدد الغرف</label>
											<Input
												register={register}
												type="number"
												id="rooms"
												name="rooms"
												required={true}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="rooms">المساحة</label>
											<Input
												register={register}
												type="number"
												id="area"
												name="area"
												required={true}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="bathRooms">الحمامات</label>
											<Input
												register={register}
												type="number"
												id="bathRooms"
												name="bathRooms"
												required={true}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="available">التوفر</label>
											<Input
												className={"checkbox"}
												register={register}
												type="checkbox"
												id="available"
												name="available"
												required={true}
											/>
										</div>
									</div>
								</div>

								{/* <div className="col-md-6">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="locationAr">
												مكان العقار بالغة العربية
											</label>
											<Input
												type="text"
												name="location.ar"
												id="locationAr"
												register={register}
												required={true}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="locationEn">
												مكان العقار بالغة الانجليزية
											</label>
											<Input
												type="text"
												name="location.en"
												id="locationEn"
												register={register}
												required={true}
											/>
										</div>
									</div>
								</div> */}
								<div className="col-md-6">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="AgentName">اسم الوكيل</label>
											<Input
												type="text"
												name="AgentName"
												id="AgentName"
												register={register}
												required={true}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="releaseDate">تاريخ الاعلان</label>
											<Input
												type="date"
												name="releaseDate"
												id="releaseDate"
												register={register}
												required={true}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="locationIfram"> لينك الموقع </label>
											<Input
												type="text"
												name="locationIfram"
												id="locationIfram"
												onChange={e => setCurrentIftame(e.target.value)}
												required={true}
												register={register}
											/>
										</div>
										<div className="card-body">
											<div className="form-item">
												<div
													className="iFramePreview"
													dangerouslySetInnerHTML={{ __html: currentIftame }}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="apartmentVideo"> لينك الفيديو </label>
											<Input
												type="text"
												name="apartmentVideo"
												id="apartmentVideo"
												onChange={e => setCurrentVideo(e.target.value)}
												required={true}
												register={register}
											/>
										</div>
										<div className="card-body">
											<div className="form-item iFramePreview">
												<ReactPlayer
													controls
													url={`${currentVideo === "" ? null : currentVideo}`}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12" style={{ height: 50 }} />
					{descriptionFields?.map((field, index) => {
						return (
							<Fragment key={v4()}>
								<div className="col-md-8">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="featureAr">
												وصف العقار بالغة العربية
											</label>
											<input
												type="text"
												name="ar"
												id="featureAr"
												{...register(`descriptions.${index}.ar`)}
											/>
										</div>
										<div className="form-item">
											<label htmlFor="featureEn">
												وصف العقار بالغة الانجليزية
											</label>
											<input
												type="text"
												name="en"
												id="featureEn"
												{...register(`descriptions.${index}.en`)}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="card-body">
										<div className="form-item mt-3">
											<div
												className="bt bt-danger btn-block secBtnForm"
												type="button"
												onClick={() => descriptionRemove(index)}
											>
												احذف ذلك الوصف
											</div>
										</div>
									</div>
								</div>
							</Fragment>
						);
					})}
					<div
						className="col-md-8"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						الرجاء اضافة وصف واحد للعقار علي الاقل
					</div>
					<div className="col-md-4">
						<div className="card-body">
							<div className="form-item mt-3">
								<div
									className="bt bt-success btn-block secBtnForm"
									onClick={handleAddDescriptions}
								>
									اضافة الوصف
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12" style={{ height: 50 }} />
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<div className="dropzoneContainer">
										<div {...getRootProps({ className: "dropzone" })}>
											<input {...getInputProps()} />
											<p>اضغط هنا لرفع الصورة الداخلية</p>
										</div>
										<aside className="thumbsContainer">{thumbs}</aside>
										<button
											onClick={handleImageUpload}
											type="button"
											className="bt bt-success"
										>
											رفع الصورة
										</button>
									</div>
									<div className="row">
										{imageArray.map(img => (
											<div className="col-md-2" key={img}>
												<div className="image-preview">
													<img
														src={`${process.env.REACT_APP_MAIN_URL}/${img}`}
														alt={img}
														className="imgList"
													/>
													<button onClick={e => deleteImage(e, img)}>
														<i className="fas fa-trash"></i>
													</button>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12" style={{ height: 50 }} />

					<div className="col-md-12">
						<div className="card-body">
							<div className="form-item">
								<label htmlFor="realtedProjects">عقارات مشابهة</label>
								<Select2
									id="realtedProjects"
									closeMenuOnSelect={false}
									components={animatedComponents}
									isMulti
									onChange={value => setValue("relatedApartment", value)}
									options={allApartMents}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-12" style={{ height: 50 }} />
					{featureFields?.map((field, index) => {
						return (
							<Fragment key={v4()}>
								<div className="col-md-8">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="featureAr">
												ميزة العقار بالغة العربية
											</label>
											<input
												type="text"
												name="ar"
												id="featureAr"
												{...register(`features.${index}.ar`)}
											/>
										</div>
										<div className="form-item">
											<label htmlFor="featureEn">
												ميزة العقار بالغة الانجليزية
											</label>
											<input
												type="text"
												name="en"
												id="featureEn"
												{...register(`features.${index}.en`)}
											/>
										</div>
										<div className="form-item">
											<label htmlFor="featureCount">كمية الميزة</label>
											<input
												type="number"
												name="count"
												id="featureCount"
												{...register(`features.${index}.count`)}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="featureIcon">ايكون الميزة</label>
											<input
												type="file"
												id="featureIcon"
												{...register(`features.${index}.icon`, {
													onChange: () => handleFeaturePicture(index),
												})}
											/>
											<input
												className="hiddenInput"
												type="text"
												name="iconPath"
												id="featureEn"
												{...register(`features.${index}.iconPath`)}
											/>
											<img
												src={
													getValues(`features.${index}.icon`) ===
													userPlaceholder
														? userPlaceholder
														: `${process.env.REACT_APP_MAIN_URL}/${getValues(
																`features.${index}.iconPath`
														  )}`
												}
												alt="avatar"
												className="imagePreview-2"
											/>
										</div>
										<div className="card-body">
											<div className="form-item mt-3">
												<div
													className="bt bt-danger btn-block secBtnForm"
													type="button"
													onClick={() => featureRemove(index)}
												>
													احذف تلك الميزة
												</div>
											</div>
										</div>
									</div>
								</div>
							</Fragment>
						);
					})}
					<div
						className="col-md-8"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						الرجاء اضافة ميزة واحدة للعقار علي الاقل{" "}
					</div>
					<div className="col-md-4">
						<div className="card-body">
							<div className="form-item mt-3">
								<div
									className="bt bt-success btn-block secBtnForm"
									onClick={handleAddFeature}
								>
									اضافة الميزة
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12" style={{ height: 50 }} />
					<div className="col-md-12">
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
					{/* <div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="picture">البانر</label>
									<input
										type="file"
										id="picture"
										{...register("pictureUrl", {
											required: true,
											onChange: () => handlePicture(),
										})}
									/>
									<img src={pictureImg} alt="avatar" className="imagePreview" />
								</div>
							</div>
						</div>
						<input
							type="text"
							name=""
							id=""
							style={{ display: "none" }}
							{...register("picturePath")}
						/>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div> */}
				</div>

				{/* array values */}
				<input
					type="checkbox"
					className="hiddenInput"
					{...register("images")}
				/>
				<input
					type="checkbox"
					className="hiddenInput"
					{...register("relatedApartment")}
				/>
			</form>
		</section>
	);
};
export default RealEstateCreate;

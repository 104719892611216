import React, { useEffect, useState } from "react";
import Loader from "../../components/shared/Loader";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateMeta } from "../../store/actions/metaAction";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import cookie from "js-cookie";
import server, { uploadServer } from "../../api/server";

const AboutUsShow = ({ match }) => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const {
		params: { aboutUsSectionId },
	} = match;

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		setValue,
		getValues,
		reset,
	} = useForm();

	const [pictureImg, setPictureImg] = useState(userPlaceholder);

	const onStart = async () => {
		dispatch(
			updateMeta({
				title: "اضافة فئة جديدة",
				breadcrumb: "الفئات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		try {
			const initialData = await server(cookie).get(
				`/pages/about-us/sections/${aboutUsSectionId}`
			);
			setPictureImg(
				`${process.env.REACT_APP_MAIN_URL}/${initialData?.data?.data?.section?.image}`
			);
			setValue("title", initialData?.data?.data?.section?.sectionTitle);
			setValue("subTitle", initialData?.data?.data?.section?.subTitle);
			setValue("description", initialData?.data?.data?.section?.description);
			setValue("picturePath", initialData?.data?.data?.section?.image);
		} catch (error) {
			console.log(error);
		}
	};

	// handleMethods
	const submitHandler = async data => {
		setLoading(true);
		try {
			const imageResponse = async () => {
				if (data.pictureUrl.length <= 0) return data.picturePath;
				const dataForm = new FormData();
				dataForm.append("file", data.pictureUrl[0]);
				const imageData = await uploadServer(cookie).post("/upload", dataForm);
				return imageData?.data?.data?.file?.path;
			};
			const imageData = await imageResponse();

			const finalData = {
				sectionTitle: data.title,
				subTitle: data.subTitle,
				description: data.description,
				image: imageData,
			};

			const dataResponse = await server(cookie).put(
				`/pages/about-us/sections/${aboutUsSectionId}`,
				{
					section: finalData,
				}
			);
			reset();
			onStart();
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const handlePicture = () => {
		const tempValue = getValues("pictureUrl");
		if (tempValue.length < 1) return;
		const blobUrl = URL?.createObjectURL(tempValue?.[0]);
		setValue("picturePath", blobUrl);
		setPictureImg(blobUrl);
	};

	// watchers
	useEffect(() => {
		onStart();
	}, []);

	console.log(errors);

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit(submitHandler)}>
				<div className="row">
					<div className="col-md-8">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="titleAr">
										العنوان الرئيسي باللغة العربية
									</label>
									<input
										type="text"
										name="titleAr"
										id="titleAr"
										{...register("title.ar", {
											required: true,
										})}
									/>
								</div>
							</div>
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="titleEn">
										العنوان الرئيسي بالغة الانجليزية
									</label>
									<input
										type="text"
										name="titleEn"
										id="titleEn"
										{...register("title.en", {
											required: true,
										})}
									/>
								</div>
							</div>
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="subTitleAr">
										العنوان الفرعي باللغة العربية
									</label>
									<input
										type="text"
										name="subTitleAr"
										id="subTitleAr"
										{...register("subTitle.ar", {
											required: true,
										})}
									/>
								</div>
							</div>
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="subTitleEn">
										العنوان الفرعي بالغة الانجليزية
									</label>
									<input
										type="text"
										name="subTitleEn"
										id="subTitleEn"
										{...register("subTitle.en", {
											required: true,
										})}
									/>
								</div>
							</div>
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="descriptionAr">الوصف باللغة العربية</label>
									<textarea
										name="descriptionAr"
										id="descriptionAr"
										cols="30"
										rows="10"
										{...register("description.ar", {
											required: true,
										})}
									></textarea>
								</div>
							</div>
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="descriptionEn">الوصف بالغة الانجليزية</label>
									<textarea
										name="descriptionEn"
										id="descriptionEn"
										cols="30"
										rows="10"
										{...register("description.en", {
											required: true,
										})}
									></textarea>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="picture">البانر</label>
									<input
										type="file"
										id="picture"
										{...register("pictureUrl", {
											required: false,
											onChange: () => handlePicture(),
										})}
									/>
									<img src={pictureImg} alt="avatar" className="imagePreview" />
								</div>
							</div>
						</div>
						<input
							type="text"
							name=""
							id=""
							style={{ display: "none" }}
							{...register("picturePath")}
						/>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default AboutUsShow;

let id = 1;
const randomId = () => {
	id++;
	return id;
};

export const navbarItems = [
	{
		id: randomId(),
		link: "/",
		icon: "fas fa-home",
		title: "لوحة التحكم",
		children: [],
	},
	{
		id: randomId(),
		link: "/moderators/index",
		icon: "fas fa-users-cog",
		title: "المشرفين",
		children: [
			{
				id: randomId(),
				title: "جميع المشرفين",
				link: "/moderators/index",
			},
			{
				id: randomId(),
				title: "اضافة مشرف جديد",
				link: "/moderators/create",
			},
		],
	},
	{
		id: randomId(),
		link: "/category/index",
		icon: "fas fa-object-group",
		title: "الفئة",
		children: [
			{
				id: randomId(),
				title: "جميع الفئة",
				link: "/category/index",
			},
			{
				id: randomId(),
				title: "اضافة الفئة جديدة",
				link: "/category/create",
			},
		],
	},
	{
		id: randomId(),
		link: "/realestate/index",
		icon: "fas fa-solid fa-building",
		title: "العقارات",
		children: [
			{
				id: randomId(),
				title: "جميع العقارات",
				link: "/realestate/index",
			},
			{
				id: randomId(),
				title: "اضافة عقار جديدة",
				link: "/realestate/create",
			},
			{
				id: randomId(),
				title: "جميع الانواع",
				link: "/apartmentType/index",
			},
			{
				id: randomId(),
				title: "اضافة نوع جديدة",
				link: "/apartmentType/create",
			},
		],
	},

	{
		id: randomId(),
		link: "/comments/index",
		icon: "fas fa-comments",
		title: "اراء العملاء",
		children: [
			{
				id: randomId(),
				title: "جميع الاراء",
				link: "/comments/index",
			},
			{
				id: randomId(),
				title: "اضافة رائ جديدة",
				link: "/comments/create",
			},
		],
	},
	{
		id: randomId(),
		link: "/orders/index",
		icon: "fas fa-envelope",
		title: "الطلبات",
		children: [],
	},
	{
		id: randomId(),
		link: "/social",
		title: "وسائل التواصل",
		icon: "fas fa-id-card",
		children: [
			// {
			// 	id: randomId(),
			// 	title: " انواع التواصل",
			// 	link: "/social/socialType/index",
			// },
			// {
			// 	id: randomId(),
			// 	title: "اضافة نوع تواصل",
			// 	link: "/social/socialType/create",
			// },
			{
				id: randomId(),
				title: " روابط التواصل",
				link: "/social/links/index",
			},
			{
				id: randomId(),
				title: "اضافة رابط تواصل",
				link: "/social/links/create",
			},
		],
	},
	{
		id: randomId(),
		link: "/siteinfo",
		icon: "far fa-address-card",
		title: " بيانات الموقع ",
		children: [
			{
				id: randomId(),
				title: "لافتات الترحيب",
				link: "/siteinfo/heroSection/index",
			},
			{
				id: randomId(),
				title: "عن تماليك السلطان",
				link: "/siteinfo/aboutUs/index",
			},
			{
				id: randomId(),
				title: "لماذا تختار تماليك السلطان",
				link: "/siteinfo/whyUs/index",
			},

			// {
			// 	id: randomId(),
			// 	title: "البيانات الاضافيه",
			// 	link: "/aboutPage/sideinfo/index",
			// },
			// {
			// 	id: randomId(),
			// 	title: "المميزات",
			// 	link: "/aboutPage/advantages/index",
			// },
			// {
			// 	id: randomId(),
			// 	title: "الاحصائيات",
			// 	link: "/aboutPage/statistics/index",
			// },
			// {
			// 	id: randomId(),
			// 	title: "الأسئله الشائعه",
			// 	link: "/aboutPage/questions/index",
			// },
		],
	},
];

import React, { useEffect, useState } from "react";
import Loader from "../components/shared/Loader";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { updateMeta } from "../store/actions/metaAction";
import { useDispatch } from "react-redux";
import { buttons } from "../helpers/data/settingsButtons";
import { Container, Row, Col } from "reactstrap";

const Settings = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [settings, setSettings] = useState([]);
	const [dataloaded, setDatalaoded] = useState(false);

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اعدادات الموقع",
				breadcrumb: "الاعدادات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/settings/siteInfo`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setSettings(response.data.data.siteInfo);
				setLoading(false);
				setDatalaoded(true);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handleChange = e => {
		let objIndex = settings.findIndex(obj => obj.name == e.target.name);
		let items = [...settings];
		items[objIndex].value = e.target.value;
		setSettings(items);
	};

	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
		axios
			.put(
				`${process.env.REACT_APP_API_URL}settings/siteInfo`,
				{ ...settings },
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				toast.success("تم التحديث بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	const handleSetting = type => {
		setLoading(true);
		axios
			.get(`${process.env.REACT_APP_API_URL}/settings/${type}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				console.log(response);
				// setSettings(response.data.data);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handleChangeFile = name => {
		setLoading(true);
		const pictureTwo = document.querySelector(`#${name}`);
		const data = new FormData();
		data.append("file", pictureTwo.files[0]);
		axios
			.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				axios
					.put(
						`${process.env.REACT_APP_API_URL}settings/siteInfo`,
						{
							...settings,
							appLogo: response.data.data.file.path,
						},
						{
							headers: {
								Authorization: `Bearer ${cookie.get("token")}`,
								Accept: "application/json",
							},
						}
					)
					.then(function (response) {
						toast.success("تم التحديث بنجاح!");
						window.location.reload();
						setLoading(false);
					})
					.catch(function (error) {
						toast.error("خطأ، برجاء المحاولة مره اخرى!");
						setLoading(false);
					});
			})
			.catch(error => {
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<ul className="list-inline filterSettings">
				{buttons.map(button => (
					<li className="list-inline-item" key={button.id}>
						<button
							className="bt bt-success"
							onClick={() => handleSetting(button.link)}
						>
							{button.title}
						</button>
					</li>
				))}
			</ul>
			<form onSubmit={handleSubmit}>
				<div className="row">
					{loading === false}
					{
						<div className="col-md-12">
							<Container>
								<Row>
									<Col xs={12} md={8}>
										<div className="form-item">
											<label htmlFor={"appNameAr"}>{" اسم الموقع"}</label>
											<input
												type="text"
												name={"appNameAr"}
												value={settings?.appName?.ar ?? ""}
												onChange={e =>
													dataloaded &&
													setSettings({
														...settings,
														appName: {
															...settings.appName,
															ar: e.target.value,
														},
													})
												}
												id={"appNameAr"}
											/>
										</div>
										<div className="form-item">
											<label htmlFor={"appNameEn"}>
												{" اسم الموقع بالانجليزية"}
											</label>
											<input
												type="text"
												name={"appNameEn"}
												value={settings?.appName?.en ?? ""}
												onChange={e =>
													dataloaded &&
													setSettings({
														...settings,
														appName: {
															...settings.appName,
															en: e.target.value,
														},
													})
												}
												id={"appNameEn"}
											/>
										</div>

										<div className="form-item">
											<label htmlFor={"appDescAr"}>{"الوصف"}</label>
											<textarea
												name={"appDescAr"}
												id={"appDescAr"}
												cols="30"
												rows="3"
												onChange={e =>
													dataloaded &&
													setSettings({
														...settings,
														appDesc: {
															...settings.appDesc,
															ar: e.target.value,
														},
													})
												}
												value={settings?.appDesc?.ar ?? ""}
											/>
										</div>
										<div className="form-item">
											<label htmlFor={"appDescEn"}>
												{" الوصف بالانجليزية"}
											</label>
											<textarea
												name={"appDescEn"}
												id={"appDescEn"}
												cols="30"
												rows="3"
												onChange={e =>
													dataloaded &&
													setSettings({
														...settings,
														appDesc: {
															...settings.appDesc,
															en: e.target.value,
														},
													})
												}
												value={settings?.appDesc?.en ?? ""}
											/>
										</div>
									</Col>
									<Col xs={12} md={4}>
										<div className="form-item">
											<label htmlFor={"AppLogo"}>{"شعار الموقع"}</label>
											<input
												type="file"
												name={"AppLogo"}
												onChange={() => handleChangeFile("AppLogo")}
												id={"AppLogo"}
											/>
											<img
												src={process.env.REACT_APP_MAIN_URL + settings?.appLogo}
												alt=""
											/>
										</div>
									</Col>
									<Col xs={12} md={12}>
										<div className="form-item">
											<label htmlFor={"LocationIframe"}>{"إطار الخريظة"}</label>
											<input
												type="text"
												name={"LocationIframe"}
												id={"LocationIframe"}
												value={settings?.mapframe ?? ""}
												onChange={e =>
													dataloaded &&
													setSettings({
														...settings,
														mapframe: e.target.value,
													})
												}
											/>
										</div>
									</Col>
								</Row>
							</Container>
						</div>
					}
				</div>
				<div className="row">
					<div className="col-md-12">
						<button className="bt bt-success">ارسال</button>
					</div>
				</div>
			</form>
		</section>
	);
};

export default Settings;

/*
settings.map(setting => (
							<div className="col-md-6" key={setting.name}>
								<div className="form-item">
									<label htmlFor={setting.name}>{setting.trans_name}</label>
									{setting.type === "textarea" ? (
										<textarea
											name={setting.name}
											id={setting.name}
											cols="30"
											rows="3"
											onChange={handleChange}
											value={setting.value}
										/>
									) : setting.type === "select" ? (
										<select
											name={setting.name}
											id={setting.name}
											value={setting.value}
											onChange={handleChange}
										>
											<option value="0">غير مفعل</option>
											<option value="1">مفعل</option>
										</select>
									) : setting.type === "file" ? (
										<input
											type="file"
											name={setting.name}
											onChange={() => handleChangeFile(setting.name)}
											id={setting.name}
										/>
									) : setting.type === "color" ? (
										<input
											type="color"
											name={setting.name}
											value={JSON.stringify(setting.value)}
											onChange={handleChange}
											id={setting.name}
										/>
									) : (
										<input
											type="text"
											name={setting.name}
											value={setting.value + ""}
											onChange={handleChange}
											id={setting.name}
										/>
									)}
								</div>
							</div>
						))} 
*/

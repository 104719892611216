import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const GeustRoute = ({ component: Component, ...rest }) => {
	const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

	return (
		<Route
			{...rest}
			render={props =>
				!isLoggedIn ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: "/dashboard",
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
};

export default GeustRoute;

import * as types from "../types";

export const login = user => {
	return {
		type: types.LOG_IN,
		payload: user,
	};
};

export const logout = () => {
	return {
		type: types.LOG_OUT,
	};
};

import logo from "../../assets/images/ejjadh-logo.svg";

const Footer = () => {
	return (
		<footer>
			<p>
				Developed by:
				<a href="https://www.ejjadh.com/" target="_blank">
					<img src={logo} alt="Ejjadh" />
				</a>
				. All Rights Reserved.
			</p>
		</footer>
	);
};

export default Footer;

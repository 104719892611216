import React, { useEffect, useState } from "react";
import Loader from "../../components/shared/Loader";
import { Link } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";
import server from "../../api/server";

const CategoriesIndex = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const dispatch = useDispatch();

	const onStart = async () => {
		dispatch(
			updateMeta({
				title: "اختار الفئة التي تبحث عنها",
				breadcrumb: "الفئات",
				button: {
					show: true,
					text: "اضافة فئة جديدة",
					link: "/category/create",
				},
			})
		);
		try {
			const pageData = await server(cookie).get("/pages/categories");
			setData(pageData.data.data.page.sections);
			setLoading(false);
		} catch (error) {
			toast.error("خطأ، برجاء المحاولة لاحقا!");
			setLoading(false);
		}
	};

	const handleDelete = postID => {
		axios
			.delete(
				`${process.env.REACT_APP_API_URL}/pages/categories/sections/${postID}`,
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				onStart();
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	useEffect(() => {
		onStart();
	}, []);
	return (
		<section>
			{loading && <Loader />}
			<section>
				<div className="row">
					{data.map(post => (
						<div className="col-md-3" key={post.slug}>
							<div className="serviceItem">
								<button onClick={() => handleDelete(post.slug)}>
									<i className="fas fa-trash"></i>
								</button>
								<Link to={`/category/show/${post.slug}`}>
									<img
										src={`${process.env.REACT_APP_MAIN_URL}/${post.image}`}
										alt={post.name.ar ?? ""}
									/>
									<h1>{post.name?.ar ?? ""}</h1>
									<p>{post.descriptionObj?.ar ?? ""}</p>
								</Link>
							</div>
						</div>
					))}
				</div>
			</section>
		</section>
	);
};

export default CategoriesIndex;

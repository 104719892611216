import Breadcrumb from "./Breadcrumb";
import menu from "../../assets/images/icons/menu.svg";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";

const Header = props => {
	const toggleMenu = () => {
		const sidebar = document.getElementById("sidebar");
		sidebar.classList.toggle("sidebar-sm");
		const contentWrapper = document.getElementById("content-wrapper");
		contentWrapper.classList.toggle("wrapper-sm");
	};

	const toggleResponsiveMenu = () => {
		const sidebar = document.getElementById("sidebar");
		sidebar.classList.toggle("sidebar-responsive");
		const sidebarWrapper = document.getElementById("sm-wrapper");
		sidebarWrapper.classList.toggle("sm-wrapper-toggle");
	};

	return (
		<header>
			<div className="content-container">
				<div className="row">
					<div className="col-md-4 d-none d-sm-block">
						<button className="menu-button" onClick={toggleMenu}>
							<img src={menu} alt="menu" />
						</button>
					</div>
					<div className="col-5 d-block d-sm-none">
						<Link to="/">
							<img src={logo} alt="Logo" className="logo-sm" />
						</Link>
					</div>
					<div className="col-md-8 col-7">
						<ul className="list-inline header-list">
							<li className="list-inline-item d-inline-block d-sm-none">
								<button onClick={toggleResponsiveMenu} style={{ margin: 0 }}>
									<img src={menu} alt="menu" />
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="header-border"></div>
			<div className="content-container">
				<div className="row">
					<div className="col-md-6 d-none d-sm-block">
						<h1>مرحبا بعودتك...</h1>
					</div>
					<div className="col-12 col-md-6">
						<Breadcrumb breadcrumbTitle={props.breadcrumbTitle} />
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;

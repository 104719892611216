import * as types from "../types";

export const updateMeta = meta => {
	return {
		type: types.UPDATE_META,
		payload: meta,
	};
};

export const logout = () => {
	return {
		type: types.LOG_OUT,
	};
};

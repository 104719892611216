const Notifications = () => {
	const toggleNotifications = () => {
		const notifications = document.getElementById("notifications");
		notifications.classList.toggle("notifications-toggle");
		const notificationsWrapper = document.getElementById("not-sm-wrapper");
		notificationsWrapper.classList.toggle("not-sm-wrapper-toggle");
	};

	return (
		<>
			<section className="notifications" id="notifications">
				<div className="header">
					<h2>الاشعارات</h2>
					<button onClick={toggleNotifications}>
						<i className="fas fa-times"></i>
					</button>
				</div>
				<ul className="nav nav-tabs" id="myTab" role="tablist">
					<li className="nav-item" role="presentation">
						<a
							className="nav-link active"
							id="home-tab"
							data-toggle="tab"
							href="#home"
							role="tab"
							aria-controls="home"
							aria-selected="true"
						>
							الاعضاء
						</a>
					</li>
					<li className="nav-item" role="presentation">
						<a
							className="nav-link"
							id="profile-tab"
							data-toggle="tab"
							href="#profile"
							role="tab"
							aria-controls="profile"
							aria-selected="false"
						>
							المنتجات
						</a>
					</li>
					<li className="nav-item" role="presentation">
						<a
							className="nav-link"
							id="contact-tab"
							data-toggle="tab"
							href="#contact"
							role="tab"
							aria-controls="contact"
							aria-selected="false"
						>
							الرسائل
						</a>
					</li>
				</ul>
				<div className="tab-content" id="myTabContent">
					<div
						className="tab-pane fade show active"
						id="home"
						role="tabpanel"
						aria-labelledby="home-tab"
					>
						<ul className="list-unstyled list">
							<li>
								<a href="">
									عضو جديد مسجل باسم محمد احمد
									<h6>15-5-2015</h6>
								</a>
							</li>
							<li>
								<a href="">
									عضو جديد مسجل باسم محمد احمد
									<h6>15-5-2015</h6>
								</a>
							</li>
							<li>
								<a href="">
									عضو جديد مسجل باسم محمد احمد
									<h6>15-5-2015</h6>
								</a>
							</li>
							<li>
								<a href="">
									عضو جديد مسجل باسم محمد احمد
									<h6>15-5-2015</h6>
								</a>
							</li>
							<li>
								<a href="">
									عضو جديد مسجل باسم محمد احمد
									<h6>15-5-2015</h6>
								</a>
							</li>
							<li>
								<a href="">
									عضو جديد مسجل باسم محمد احمد
									<h6>15-5-2015</h6>
								</a>
							</li>
							<li>
								<a href="">
									عضو جديد مسجل باسم محمد احمد
									<h6>15-5-2015</h6>
								</a>
							</li>
							<li>
								<a href="">
									عضو جديد مسجل باسم محمد احمد
									<h6>15-5-2015</h6>
								</a>
							</li>
						</ul>
					</div>
					<div
						className="tab-pane fade"
						id="profile"
						role="tabpanel"
						aria-labelledby="profile-tab"
					>
						...
					</div>
					<div
						className="tab-pane fade"
						id="contact"
						role="tabpanel"
						aria-labelledby="contact-tab"
					>
						...
					</div>
				</div>
			</section>
			<section
				className="not-sm-wrapper d-block d-sm-none"
				id="not-sm-wrapper"
				onClick={toggleNotifications}
			></section>
		</>
	);
};

export default Notifications;

import React, { useEffect, useState } from "react";
import Loader from "../../components/shared/Loader";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateMeta } from "../../store/actions/metaAction";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import cookie from "js-cookie";
import server, { uploadServer } from "../../api/server";
import axios from "axios";

const CategoriesCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		setValue,
		getValues,
		reset,
	} = useForm();

	const [pictureImg, setPictureImg] = useState(userPlaceholder);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة فئة جديدة",
				breadcrumb: "الفئات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	// handleMethods
	const submitHandler = async data => {
		setLoading(true);
		try {
			const dataForm = new FormData();
			dataForm.append("file", data.pictureUrl[0]);

			const imageResponse = await uploadServer(cookie).post(
				"/upload",
				dataForm
			);

			const finalData = {
				title: `${data?.nameEn} category`,
				name: {
					ar: data?.nameAr,
					en: data?.nameEn,
				},
				image: imageResponse.data.data.file.path,
			};

			const dataResponse = await server(cookie).post(
				"/pages/categories/sections",
				{
					section: finalData,
				}
			);
			reset();
			setPictureImg(userPlaceholder);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const handlePicture = () => {
		const tempValue = getValues("pictureUrl");
		if (tempValue.length < 1) return;
		const blobUrl = URL?.createObjectURL(tempValue?.[0]);
		setValue("picturePath", blobUrl);
		setPictureImg(blobUrl);
	};

	// watchers
	useEffect(() => {
		onStart();
	}, []);

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit(submitHandler)}>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="nameAr">اسم الفئة باللغة العربية</label>
									<input
										type="text"
										name="name"
										id="nameAr"
										{...register("nameAr", {
											required: true,
										})}
									/>
								</div>
							</div>
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="nameEn">اسم الفئة بالغة الانجليزية</label>
									<input
										type="text"
										name="name"
										id="nameEn"
										{...register("nameEn", {
											required: true,
										})}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="picture">البانر</label>
									<input
										type="file"
										id="picture"
										{...register("pictureUrl", {
											required: true,
											onChange: () => handlePicture(),
										})}
									/>
									<img
										src={pictureImg}
										alt="avatar"
										className="imagePreview-2"
									/>
								</div>
							</div>
						</div>
						<input
							type="text"
							name=""
							id=""
							style={{ display: "none" }}
							{...register("picturePath")}
						/>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default CategoriesCreate;

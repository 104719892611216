import React, { useEffect, useState } from "react";
import Loader from "../../components/shared/Loader";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateMeta } from "../../store/actions/metaAction";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import cookie from "js-cookie";
import server, { uploadServer } from "../../api/server";

const TypeShow = ({ match }) => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const {
		params: { typeId },
	} = match;

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
	} = useForm();

	const onStart = async () => {
		dispatch(
			updateMeta({
				title: "اضافة نوع جديدة",
				breadcrumb: "الانواع",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		try {
			const initialData = await server(cookie).get(
				`/pages/apartment-type/sections/${typeId}`
			);
			setValue("nameAr", initialData?.data?.data?.section?.name?.ar);
			setValue("nameEn", initialData?.data?.data?.section?.name?.en);
		} catch (error) {
			console.log(error);
		}
	};

	// handleMethods
	const submitHandler = async data => {
		setLoading(true);
		try {
			const finalData = {
				name: {
					ar: data?.nameAr,
					en: data?.nameEn,
				},
			};
			const dataResponse = await server(cookie).put(
				`/pages/apartment-type/sections/${typeId}`,
				{
					section: finalData,
				}
			);
			reset();
			onStart();
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	// watchers
	useEffect(() => {
		onStart();
	}, []);

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit(submitHandler)}>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="nameAr">اسم الفئة باللغة العربية</label>
									<input
										type="text"
										name="name"
										id="nameAr"
										{...register("nameAr", {
											required: true,
										})}
									/>
								</div>
							</div>
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="nameEn">اسم الفئة بالغة الانجليزية</label>
									<input
										type="text"
										name="name"
										id="nameEn"
										{...register("nameEn", {
											required: true,
										})}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12">
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block" type="submit">
								ارسال
							</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};
export default TypeShow;

import React, { useEffect, useState } from "react";
import Loader from "../../components/shared/Loader";
import { Link } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";
import server from "../../api/server";

const WhyUsIndex = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const dispatch = useDispatch();

	const onStart = async () => {
		dispatch(
			updateMeta({
				title: "لماذا نحن ؟",
				breadcrumb: "لماذا نحن ؟",
				button: {
					show: false,
					text: "اضافة نوع جديدة",
					link: "/apartmentType/create",
				},
			})
		);
		try {
			const pageData = await server(cookie).get("/pages/why-us");
			setData(pageData.data.data.page.sections);
			setLoading(false);
		} catch (error) {
			toast.error("خطأ، برجاء المحاولة لاحقا!");
			setLoading(false);
		}
	};

	useEffect(() => {
		onStart();
	}, []);

	return (
		<section>
			{loading && <Loader />}
			<section>
				<div className="row">
					{data.map(post => (
						<div className="col-md-3" key={post.slug}>
							<div className="serviceItem">
								<Link to={`/siteinfo/whyUs/show/${post.slug}`}>
									<img
										src={`${process.env.REACT_APP_MAIN_URL}/${post.icon}`}
										alt={post.title ?? ""}
									/>
									<h1>{post.title ?? ""}</h1>
								</Link>
							</div>
						</div>
					))}
				</div>
			</section>
		</section>
	);
};

export default WhyUsIndex;

import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import Loader from "../../components/shared/Loader";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";
import { useRef } from "react";
import { useMemo } from "react";
import { Col, Container, Row } from "reactstrap";

const ContactIndex = () => {
	const [contacts, setContacts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedOrder, setselectedOrder] = useState({});
	const dispatch = useDispatch();
	const formInfo = useRef(null);

	useEffect(() => {
		onStart();
		formInfo.current.style.display = "none";
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "جميع الرسائل المرسلة من الزوار",
				breadcrumb: "اتصل بنا",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);

		axios
			.get(`${process.env.REACT_APP_API_URL}/contactus`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setContacts(response.data.data.contacts);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};
	const renderView = order => {
		setLoading(true);
		formInfo.current.style.display = "flex";
		setselectedOrder({ ...order });
	};

	const renderSelectedContact = useMemo(() => {
		setLoading(false);
		return (
			<Container
				style={{
					background: "white",
					paddingTop: "2rem",
					paddingBottom: "2rem",
					borderRadius: "10px",
				}}
			>
				<div className="row">
					<div className="col-md-12">
						<div className="form-item">
							<label htmlFor="name">الاسم</label>
							<input
								type="text"
								id="name"
								disabled
								value={selectedOrder?.firstName}
							/>
						</div>
						{/* <div className="form-item">
								<label htmlFor="phone"> اسم الشريكة </label>
								<input
									type="text"
									id="phone"
									disabled
									value={selectedOrder?.others?.companyName}
								/>
							</div> */}
						<div className="form-item">
							<label htmlFor="email">البريد الالكتروني</label>
							<input
								type="email"
								id="email"
								disabled
								value={selectedOrder?.email}
							/>
						</div>
						<div className="form-item">
							<label htmlFor="phone">رقم الهاتف</label>
							<input
								type="text"
								id="phone"
								disabled
								value={selectedOrder?.phone}
							/>
						</div>
						<div className="form-item">
							<label htmlFor="phone"> نوع الخدمة</label>
							<input
								type="text"
								id="phone"
								disabled
								value={selectedOrder?.others?.ServiceType}
								companyName
							/>
						</div>
						<div className="form-item">
							<label htmlFor="phone">الرسالة</label>
							<textarea
								id="message"
								cols="25"
								rows="5"
								value={selectedOrder?.message}
								readOnly={true}
								disabled
								style={{ resize: "none" }}
							/>
						</div>
					</div>
				</div>

				<button
					className="bt bt-danger bt-sm"
					onClick={() => (formInfo.current.style.display = "none")}
				>
					رجوع الي القائمة الطلبات
				</button>
			</Container>
		);
	}, [selectedOrder]);
	const columns = [
		{
			name: "ID",
			selector: "id",
			sortable: true,
		},
		{
			name: "الاسم",
			selector: "firstName",
			sortable: true,
		},
		{
			name: "البريد الالكتروني",
			selector: "email",
			sortable: true,
		},
		{
			name: "رقم الهاتف",
			selector: "phone",
			sortable: true,
		},
		{
			name: "settings",
			selector: "settings",
			cell: row => (
				<ul className="list-inline settingsList">
					<li className="list-inline-item">
						{/* <Link className="bt bt-success bt-sm" to={`/orders/show/${row.id}`}> */}
						<d className="bt bt-success bt-sm" onClick={() => renderView(row)}>
							<i className="fas fa-eye"></i>
						</d>
						{/* </Link> */}
					</li>
					<li className="list-inline-item">
						<button
							className="bt bt-danger bt-sm"
							onClick={() => handleConfrimDelete(row.id)}
						>
							<i className="fas fa-trash"></i>
						</button>
					</li>
				</ul>
			),
		},
	];

	const handleConfrimDelete = contactID => {
		confirmAlert({
			title: "تأكيد الحذف",
			message: "هل أنت متأكد من رغبتك في الحذف؟",
			buttons: [
				{
					label: "نعم",
					onClick: () => deleteContact(contactID),
				},
				{
					label: "لا",
					onClick: () => console.log("No"),
				},
			],
		});
	};

	const deleteContact = contactID => {
		axios
			.delete(`${process.env.REACT_APP_API_URL}/contactUs/${contactID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				onStart();
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<div
				style={{
					zIndex: "100000000",
					background: "#141414b5",
					position: "fixed",
					width: "80vw",
					height: "100vh",
					top: "0",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					left: "0",
					padding: "3rem",
					paddingTop: "1.3rem",
				}}
				ref={formInfo}
			>
				{renderSelectedContact}
			</div>
			<div className="datatable">
				<DataTableExtensions columns={columns} data={contacts}>
					<DataTable
						noHeader
						defaultSortField="id"
						defaultSortAsc={false}
						pagination
						highlightOnHover
						direction="rtl"
						striped
					/>
				</DataTableExtensions>
			</div>
		</section>
	);
};

export default ContactIndex;

import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import cookie from "js-cookie";
import { useDispatch } from "react-redux";
import { login } from "../../store/actions/userActions";
import logo from "../../assets/images/logo.png";
import "../../styles/login.scss";

const Login = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const handleSubmit = e => {
		e.preventDefault();
		if (email == "" || password == "") {
			toast.error("برجاء تعبئة الحقول!");
		} else {
			axios
				.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
					email,
					password,
				})
				.then(async function (response) {
					cookie.set("token", response.data.data.token);
					await dispatch(login(response.data.data));
					history.push("/");
					toast.success(`مرحبا بك يا ${response.data.data.name}`);
				})
				.catch(function (error) {
					toast.error("البيانات المدخلة غير صحيحة!");
				});
		}
	};

	return (
		<div className="limiter">
			<div className="container-login100">
				<div className="wrap-login100 p-b-160 p-t-50">
					<img src={logo} alt="Ejjadh" className="logo-login" />
					<form className="login100-form validate-form" onSubmit={handleSubmit}>
						<div
							className="wrap-input100 rs1 validate-input"
							data-validate="Username is required"
						>
							<input
								type="email"
								name="email"
								id="email"
								className="input100"
								onChange={e => setEmail(e.target.value)}
								placeholder="البريد الالكتروني"
							/>
						</div>
						<div
							className="wrap-input100 rs2 validate-input"
							data-validate="Password is required"
						>
							<input
								type="password"
								name="password"
								id="password"
								className="input100"
								placeholder="كلمة المرور"
								onChange={e => setPassword(e.target.value)}
							/>
						</div>
						<div className="container-login100-form-btn">
							<button className="login100-form-btn">دخول</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Login;
